import React from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import Button from '../ui/Button';

interface Props {
	selector: string;
}

function ExploreButton({ selector }: Props) {
	const { scroll } = useLocomotiveScroll();

	const clickExploreHandler = () => {
		scroll.scrollTo(document.querySelector(selector));
	};

	return (
		<Button className="button--light button--icon explore-button" onClick={clickExploreHandler}>
			Explore
			<svg className="button__icon">
				<path d="M5.77566 5.51709L0.663666 0.869823L1.33634 0.129883L7.22435 5.48262L1.85356 10.8534L1.14645 10.1463L5.77566 5.51709Z" />
			</svg>
			<svg className="button__icon">
				<path d="M5.77566 5.51709L0.663666 0.869823L1.33634 0.129883L7.22435 5.48262L1.85356 10.8534L1.14645 10.1463L5.77566 5.51709Z" />
			</svg>
			<svg className="button__icon">
				<path d="M5.77566 5.51709L0.663666 0.869823L1.33634 0.129883L7.22435 5.48262L1.85356 10.8534L1.14645 10.1463L5.77566 5.51709Z" />
			</svg>
		</Button>
	);
}

export default ExploreButton;
