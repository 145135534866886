import React, { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import moment from 'moment';
import ExploreButton from '../ui/ExploreButton';
import FooterPage from '../layout/FooterPage';
import Button from '../ui/Button';

interface Props {
	bgImgSrc: string;
	introHeading: string;
	introText: string;
	videoTitle: string;
	videoIntro: string;
	video: any;
	recipientsTitle: string;
	recipients: any;
	transitionStatus: any;
}

function RCMI({ bgImgSrc, introHeading, introText, videoTitle, videoIntro, video, recipientsTitle, recipients, transitionStatus }: Props) {
	const { scroll } = useLocomotiveScroll();

	useEffect(() => {
		if (scroll === null) {
			return;
		}

		setTimeout(() => {
			scroll.destroy();
			scroll.init();
		}, 1000);
	}, [scroll]);

	return (
		<>
			<section id="rcmi-page" className="about-page rcmi-page section--fluid" data-scroll-section data-bg-colour="black">
				<div className="section-title" data-scroll data-scroll-speed="1" data-scroll-direction="vertical">
					<h2>RCMI</h2>
				</div>
				<div className="about-page__bg">
					<div className={`animate inner${transitionStatus ? ` inner--${transitionStatus}` : ''}`} style={{ backgroundImage: `url('${bgImgSrc}')` }} />
					<ExploreButton selector="#content" />
				</div>
				<div className="container" id="content">
					<div className="about-page__content-section">
						<div className="about-page__quote">
							<h2>{introHeading}</h2>
						</div>
						<div className="about-page__content">
							<p dangerouslySetInnerHTML={{ __html: introText }} />
						</div>
					</div>
					<div className="rcmi-page__cta">
						<Button opensNewTab url="https://filmnorth.org/fiscally-sponsored-projects/the-roderick-cox-music-initiative/" className="button--block">Make a donation</Button>
					</div>
					<div className="about-page__content-section rcmi-page__video-section">
						<div className="about-page__quote">
							<h2>{videoTitle}</h2>
							<p>{videoIntro}</p>
						</div>
						<div className="about-page__content" dangerouslySetInnerHTML={{ __html: video }} />
					</div>
					<div className="rcmi-page__recipients">
						<h2 className="rcmi-page__recipients-title">
							<span>{recipientsTitle}</span>
						</h2>
						{recipients && <div className="rcmi-page__recipients-items">
							{recipients.map((x: any) => (
								<div className="rcmi-page__recipients-item">
									{x.date && <p className="date">{moment(x.date, 'dd/mm/yy').format('MMMM YYYY')}</p>}
									<p className="title">{x.title}</p>
									{x.description && <p dangerouslySetInnerHTML={{ __html: x.description }} />}
								</div>
							))}
						</div>}
					</div>
					<div className="rcmi-page__cta">
						<Button opensNewTab url="https://filmnorth.org/fiscally-sponsored-projects/the-roderick-cox-music-initiative/" className="button--block">Make a donation</Button>
					</div>
				</div>
			</section>
			<FooterPage />
		</>
	);
}

export default RCMI;
