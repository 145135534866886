import React, { useRef, useEffect } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Seo from '../components/utils/Seo';
import Header from '../components/layout/Header';
import Cursor from '../components/utils/Cursor';
import Nav from '../components/modules/Nav';
import RCMI from '../components/pages/RCMI';
import Loader from '../components/modules/Loader';

function RCMIPageTemplate({ pageContext, transitionStatus }: any) {
	const containerRef = useRef(null);

	useEffect(() => {
		document.body.classList.remove('bg-white', 'bg-black', 'bg-brand-one', 'bg-home');
	}, []);

	return (
		<>
			<Seo title="RCMI" />
			<Header />
			<LocomotiveScrollProvider
				options={
					{
						smooth: true,
						direction: 'vertical',
					}
				}
				watch={
					[]
				}
				containerRef={containerRef}
			>
				<Nav />
				<Cursor />
				<main data-scroll-container ref={containerRef}>
					<RCMI
						bgImgSrc={pageContext.acf.rcmi_banner_image.localFile.childImageSharp.fluid.src}
						introHeading={pageContext.acf.rcmi_intro_heading}
						introText={pageContext.acf.rcmi_intro_text}
						videoTitle={pageContext.acf.rcmi_video_title}
						videoIntro={pageContext.acf.rcmi_video_intro}
						video={pageContext.acf.rcmi_video}
						recipientsTitle={pageContext.acf.rcmi_recipients_title}
						recipients={pageContext.acf.rcmi_recipients}
						transitionStatus={transitionStatus}
					/>
				</main>
			</LocomotiveScrollProvider>
			<Loader transitionStatus={transitionStatus} />
		</>
	);
}

export default RCMIPageTemplate;
